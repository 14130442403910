<template>
  <div class="container-fluid flex-grow-1">
    <div class="row justify-content-center mt-2">
      <div class="col-10">
        <h1>
          <p>
            ¡Bienvenidos a
            <span style="font-family:YellowTail">Firulandia!</span>
          </p>
        </h1>
        <h3>El lugar donde hallarás toda la información necesaria para el cuidado y atención de tus mascotas</h3>
      </div>
    </div>
    <Carousel/>
  </div>
</template>

<script>
  import Carousel from '../components/Carousel.vue'
  export default {
    name: 'Home',
    components: {
      Carousel,
    },
  }
</script>

