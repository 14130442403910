<template>
  <button class="btn button"><slot/></button>
</template>

<script>
  export default {
    name: 'Button',
  }
</script>

<style scoped>
    .button {
        font-size: x-large;
        background-color: #FDD55D;
        color:#303030;
        padding: 5px 10px;
        border-radius: 25px;
        box-shadow: 0px 10px 10px 0 rgb(0 0 0 / 20%), -1px 5px 10px 0 rgb(0 0 0 / 33%)
    }
    .button:hover{
        color:#202020;
        box-shadow: 0px 5px 10px 0 rgb(0 0 0 / 50%), 0px 3px 10px 0 rgb(81 81 81 / 70%)
    }
</style>
