<template>
  <div class="row justify-content-center">
    <div class="col-9">
      <b-carousel
        class="estilos mt-2 mb-4"
        id="carousel-1"
        v-model="slide"
        :interval="6000"
        controls
        label-next=""
        label-prev=""
        fade
        @sliding-start="onSlideStart()"
        @sliding-end="onSlideEnd()">

        <b-carousel-slide
          img-src="https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/dog-puppy-on-garden-royalty-free-image-1586966191.jpg?crop=1.00xw:0.669xh;0,0.190xh&resize=1200:*">
        </b-carousel-slide>
        <b-carousel-slide 
          img-src="https://cdn.theatlantic.com/thumbor/W544GIT4l3z8SG-FMUoaKpFLaxE=/0x131:2555x1568/1600x900/media/img/mt/2017/06/shutterstock_319985324/original.jpg">
        </b-carousel-slide>

        <b-carousel-slide
          img-src="https://img.webmd.com/dtmcms/live/webmd/consumer_assets/site_images/article_thumbnails/other/dog_cool_summer_slideshow/1800x1200_dog_cool_summer_other.jpg">
        </b-carousel-slide>

        <b-carousel-slide 
          img-src="https://www.americanhumane.org/app/uploads/2016/08/animals-cats-cute-45170-min.jpg">
        </b-carousel-slide>

      </b-carousel>
    </div>
  </div>
</template>

<script>
  export default{
    name: 'Collapse',
    data() {
      return {
        slide: 0,
        sliding: null
      }
    },
    methods: {
      onSlideStart() {
        this.sliding = true
      },
      onSlideEnd() {
        this.sliding = false
      }
    }
  }
</script>