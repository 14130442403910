<template>
<div class="color">
  <footer class="page-footer font-small blue pt-4">
    <div class="container-fluid text-center text-md-left">
      <div class="row">
        <div class="col-md-6 mt-md-0 mt-3">
          <h1 style="font-family: YellowTail">Firulandia</h1>
          <p>Punta Alta 8109, Argentina</p>
        </div>

        <hr class="clearfix w-100 d-md-none pb-3">
        <div class="col-md-3 mb-md-0 mb-3 mt-2">
          <h6><b>Otras busquedas</b></h6>
          <ul class="list-unstyled">
            <li>
              <a href="https://www.facebook.com/search/top/?q=animales%20punta%20alta">Proyectos de la ciudad</a>
            </li>
            <li>
              <a href="https://www.facebook.com/groups/Perdidos-Encontrados-y-Adopciones-de-animales-Punta-Alta-Argentina-985754348225893/">Perdidos y encontrados</a>
            </li>
            
          </ul>

        </div>
      </div>
    </div>
  </footer>
</div>
</template>

<script>
  export default {
    name: 'Footer',   
  }
</script>


<style scoped>
.color {
  background-color: #ffa6a69e;
}
</style>