<template>
  <div id="app" class="h-100 d-flex flex-column ">
    <Navbar/>
    <div class="flex-grow-1 d-flex flex-column">
      <router-view></router-view>
    </div>
    <div class="row">
      <Footer/>
    </div>
  </div>
</template>

<script>
  import Navbar from './components/Navbar.vue'
  import Footer from './components/Footer.vue'

  export default {
    name: 'App',
    components: {
      Navbar,
      Footer,
    }
  }
</script>

<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #333333;
  }
  html, body {
    height: 100%
  }
</style>
