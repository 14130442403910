<template>
<div class="container-fluid">
  <div class="row justify-content-center mt-2">
    <div class="col-sm-6">
      <h3>Aquí puedes ver las fechas de guardia de cada veterinaria</h3>
    </div>
  </div>
  <div class="row justify-content-center mt-2">
    <div class="col-sm-6">
      <b-calendar
        selected-variant="warning"
        today-variant="danger"
        nav-button-variant="danger" 
        block 
        v-model="value" 
        @selected="onSelected" 
        :date-info-fn="date_class" 
        locale="es" ></b-calendar>
    </div>
  </div>
  <div class="row justify-content-center mt-2 mb-3">
    <div class="col-sm-6" v-if="hayGuardias">
      <div class="mi-texto">
        <h4>Veterinarias de guardia</h4>
      </div>
      <div v-for="guardia in datosGuardias" :key="guardia.id">
        <div class="card mb-2">
          <div class="card-text">
            <div class="row">
              <div class="col">
                <div class="card-text">
                  <b> Veterinaria: </b> {{guardia.nombre}}
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="card-text">
                  <b> Teléfono: </b> {{guardia.telefono}}
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="card-text">
                  <b> Dirección: </b> {{guardia.direccion}}
                </div>
              </div>
            </div>            
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center" v-else>
    <div class="col-sm-6">
      <div class="mi-texto">
        <h4>¡Nada por aqui!</h4>
      </div>
    </div>
  </div>
  </div>
  
</div>
</template>

<script>
  export default {
    name: 'Guardias',
    data() {
      return {
        value: '',
        selected: null,
        guardias: null,
        fechas_guardias: [],
        datosGuardias: [],
        hayGuardias: false,
      }
    },
    methods: {
      fetchGuardias() {
        return fetch(`https://firulandia-servicio-web.herokuapp.com/api/guardias`)
          .then((res) => res.json())
          .then(
            (result) => {
              this.guardias = result;
            },
            (error) => {
              this.error = error;
            }
          );
      },
      onSelected(sel) {
        this.selected = sel;
        this.esFechaGuardia();
      },
      date_class(ymd, date) {
        const guardias_del_mes = this.fechas_guardias.filter(fecha_guardia => (fecha_guardia.getMonth() === date.getMonth() & fecha_guardia.getFullYear() === date.getFullYear()));
        const encontre = guardias_del_mes.some(fecha_guardias => fecha_guardias.getDate() === date.getDate());
        return encontre ? 'pintar' : ''
      },
      assignFechas(){
        let fechas = [];
        this.guardias.forEach(guardia => {
          const fecha = guardia.fecha_guardia;
          fechas.push(new Date(fecha));
        });
        this.fechas_guardias = fechas;
      },
      esFechaGuardia(){
        let datos = [];
        const fecha_seleccionada = new Date(`${this.selected}, 00:00`);
        this.guardias.forEach(guardia => {
          const fecha_guardia = new Date(guardia.fecha_guardia);

          if(fecha_guardia.getDate() === fecha_seleccionada.getDate() && 
              fecha_guardia.getMonth() === fecha_seleccionada.getMonth() &&
              fecha_guardia.getFullYear() === fecha_seleccionada.getFullYear()) {
                datos.push(guardia);
              }
        });
        this.datosGuardias = datos;
        if(this.datosGuardias.length === 0)
          this.hayGuardias = false;
        else
          this.hayGuardias = true; 
      },
      async iniciarAsync(){
        await this.fetchGuardias();
        this.assignFechas();
      }
    },
    mounted() {
      this.iniciarAsync();
    },
  }
</script>

<style>
  .pintar{
    background-color: #ff72729e ;
  }

  .mi-texto{
    color: #262626;
    background:#ff72729e;
    border-radius: 25px;
    box-shadow: 0px 10px 10px 0 rgb(0 0 0 / 20%), -1px 5px 10px 0 rgb(255 2 131 / 20%);
  }
  .b-calendar output{
    display: none;
  }
  .card {
  background: #ffa6a69e;
}
</style>