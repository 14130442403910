<template>
  <div class="overflow-auto">
    <b-pagination-nav align="center" :link-gen="linkGen" :number-of-pages="paginasTotales" use-router></b-pagination-nav>
  </div>
</template>

<script>
  export default {
    name: 'Paginator',
    props: {
      pages: Number,
      count: Number
    },
    computed: {
      paginasTotales: function() {
        return Math.ceil(this.pages/this.count);
      }
    },
    methods: {
      linkGen(pageNum) {
        return `?page=${pageNum}`
      }
    }
  }
</script>

<style>
.pagination > li > a
{
    background-color: white;
    color: #5A4181;
}

.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover
{
    color: #262626;
    background-color: #eee;
    border-color: #ddd;
}

.pagination > .active > a
{
    color: white;
    background-color: #ee716c !Important;
    border: solid 1px #ee716c !Important;
}

.pagination > .active > a:hover
{
    background-color: #ee716c !Important;
    border: solid 1px #ee716c;
}
</style>