<template>
  <div class="container-fluid"> 
    <div class="row mt-2"> 
      <div class="col">
        <div class="input-group" >
          <div class="form-outline">
            <input v-debounce:400ms="fetchForrajesSearch" type="text" v-model="buscar" id="form1" class="form-control" placeholder="Buscar forraje.." />
          </div>
        </div>
      </div>
    </div>
    <div v-if="forrajes.length != 0">
      <div class="row">    
        <div class="col-md-6 justify-content-center" v-bind:class="{'ps-md-0': index % 2 != 0 }" v-for="(forraje, index) in forrajes" :key="forraje.id">
          <div class="d-flex flex-column h-100">
            <Card :elemento="forraje" :index="index" :clave="getKey(forraje)" :boton_extra="false"/>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <Paginator :pages="pages" :count="count"/>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="spinner-border text-danger mt-2" style="width: 3rem; height: 3rem;" role="status" v-if="primeraCarga">
        <span class="visually-hidden">Cargando...</span>
      </div>
      <div v-else>
        <img src="../assets/perrito_no_results.png" alt="No hay resultado"/>
      </div>
    </div>
  </div>
</template>

<script>
  import Card from '../components/Card.vue'
  import Paginator from '../components/Paginator.vue'

  export default {
    name: 'Forrajes',
    components: {
      Card,
      Paginator,
    },
    computed: {
      page: function() {
        return this.$route.query.page || 1
      },
      count: function() {
        return this.$route.query.count || 4
      }
    },
    data: function() {
      return {
        forrajes: [],
        pages: 1,
        error: null,
        buscar: '',
        primeraCarga: true,
      }
    },
    mounted() {
      this.fetchForrajes()
    },
    methods: {
      fetchForrajes() {
        fetch(`https://firulandia-servicio-web.herokuapp.com/api/forrajes?page=${this.page}&count=${this.count}`)
        .then((res) => res.json())
        .then(
          (result) => {
            this.forrajes = result.items;
            this.pages = result.total;
          },
          (error) => {
            this.error = error;
          }
        );
      },
      fetchForrajesSearch() {
        fetch(`https://firulandia-servicio-web.herokuapp.com/api/forrajes?search=${this.buscar}`)
        .then((res) => res.json())
        .then(
          (result) => {
            this.forrajes = result.items
            this.pages = result.total;
          },
          (error) => {
            this.error = error
          }
        ).finally(() => this.primeraCarga = false);
      },
      getKey(forraje) {
        return `${forraje.id}${forraje.nombre}`.replace(/ /g,'')
      }
    },
    watch: {
      '$route': 'fetchForrajes'
    }
  }
</script>

<style scoped>
  li {
      list-style-type: none;
  }
  .row {
    justify-content: center;
  }
  @media (max-width: 425px){
    img {
      width: 100%;
    }
  }
</style>