<template>
  <div>
    <b-collapse :id="clave" class="mt-2 myClass">
      <p class="card-text">
        <b> Días laborales: </b> {{elemento.dias}}
        <br>
        <span> <b> Horarios: </b> {{elemento.horario_inicio1}} a {{elemento.horario_cierre1}} </span>
        <span v-if="elemento.horario_inicio2!=null">y {{elemento.horario_inicio2}} a {{elemento.horario_cierre2}}</span>
        <br>
        <b> Página web: </b> <b-link href="elemento.pagina_web">{{elemento.pagina_web}}</b-link>
        <br>
        <a v-if="fecha_guardia">
        <a v-if="elemento.fecha_guardia!=null">
          <b> Fecha de guardia: </b>{{getDate(elemento.fecha_guardia)}}
        </a>
        </a>
      </p>
    </b-collapse>
  </div>
</template>

<script>
  export default {
    props: {
      elemento: Object,
      fecha_guardia: Boolean,
      clave: String,
    },
    methods: {
      getDate(fecha){
        fecha = new Date(`${fecha}`);
        let dia = fecha.getDate();
        let mes = fecha.getMonth() + 1;
        let anio = fecha.getFullYear();
        return dia+'/'+mes+'/'+anio;
      }
    }
  }
</script>
