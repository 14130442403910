import Vue from 'vue'
import Router from 'vue-router'
import home from '../views/Home.vue'
import veterinarias from '../views/Veterinarias.vue'
import forrajes from '../views/Forrajes.vue'
import guardias from '../views/Guardias.vue'
import ForrajesDeVeterinaria from '../views/ForrajesDeVeterinaria.vue'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: home
    },
    {
      path: '/veterinarias',
      name: 'veterinarias',
      component: veterinarias
    },
    {
      path: '/forrajes',
      name: 'forrajes',
      component: forrajes
    },
    {
      path: '/guardias',
      name: 'guardias',
      component: guardias
    },
    {
      path: '/veterinarias/:id/forrajes',
      name: 'forrajesDeVeterinaria',
      component: ForrajesDeVeterinaria
    }
  ],
  mode:'history'
})