<template>
  <div class="container-fluid"> 
    <div class="row mt-2" v-if="veterinaria">
      <h3>Forrajes recomendados por: <b>{{veterinaria.nombre}}</b></h3>
    </div>
    <div class="row">
      <div class="col-md-6 justify-content-center" v-bind:class="{'ps-0': index % 2 != 0 }" v-for="(forraje, index) in forrajes" :key="forraje.id">
        <div class="d-flex flex-column h-100">
          <Card :elemento="forraje" :index="index" :clave="getKey(forraje)" :boton_extra="false"/>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <Paginator :pages="pages" :count="count"/>
      </div>
    </div>
  </div>
</template>


<script>
  import Paginator from '../components/Paginator.vue'
  import Card from '../components/Card.vue'

  export default {
    name: 'ForrajesDeVeterinaria',
    components: {
      Paginator,
      Card,
    },
    computed: {
      page: function() {
        return this.$route.query.page || 1
      },
      count: function() {
        return this.$route.query.count || 4
      },
      clave: function() {
        return ((this.forraje.id + '' + this.forraje.nombre).toString()).replace(' ', '') ;
      },
    },
    data: function() {
      return {
        forrajes: null,
        veterinaria: null,
        pages: 1,
        error: null,
      }
    },
    mounted() {
      this.fetchForrajesDeVetes();
      this.fetchVeterinaria();
    },
    methods: {
      fetchForrajesDeVetes() {
        fetch(`https://firulandia-servicio-web.herokuapp.com/api/veterinarias/${this.$route.params.id}/forrajes`)
        .then((res) => res.json())
        .then(
          (result) => {
            this.forrajes = result.items;
            this.pages = result.total;
          },
          (error) => {
            this.error = error;
          }
        );
      },
      fetchVeterinaria() {
        fetch(`https://firulandia-servicio-web.herokuapp.com/api/veterinarias/${this.$route.params.id}`)
        .then((res) => res.json())
        .then(
          (result) => {
            this.veterinaria = result;
          },
          (error) => {
            this.error = error;
          }
        );
      },
      getKey(forraje) {
        return `${forraje.id}${forraje.nombre}`.replace(/ /g,'')
      }
    },
    watch: {
      '$route': 'fetchForrajesDeVetes'
    }
  }
</script>

<style scoped>
  li {
      list-style-type: none;
  }

  .row {
    justify-content: center;
  }


</style>