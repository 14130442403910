<template>
  <div>
    <b-navbar toggleable="md" type="light" color="#fdffb6">
      <b-container>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-navbar-brand href="#">
          <img alt="Firulandia logo" src="../assets/perrito.png">
        </b-navbar-brand>

        <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <b-nav-item :to="{name:'home'}">
              <Button>Inicio</Button>
            </b-nav-item>
            <b-nav-item :to="{name:'veterinarias'}">
              <Button>Veterinarias</Button>
            </b-nav-item>
            <b-nav-item :to="{name:'forrajes'}">
              <Button>Forrajes</Button>
            </b-nav-item>
            <b-nav-item :to="{name:'guardias'}">
              <Button>Guardias</Button>
            </b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-container>
    </b-navbar>
  </div>
</template>

<script>
  import Button from './Button.vue';

  export default {
    name: 'Navbar',
    components: {
      Button
    }
  }
</script>

<style scoped>
.navbar {
  background: #FF4747
}
.navbar .container {
  margin-left:30px;
}

.navbar-brand img {
  height: 175px;
}
.navbar-light .navbar-toggler {
    color: rgb(0 0 0 / 22%);
    border-color: rgb(0 0 0 / 59%);
}
</style>
