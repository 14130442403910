<template>
  <div class="card overflow-hidden mt-2">
    <div class="card-body">
      <div class="row align-items-center">
        <div class="col-md-6">
          <img class="card-img rounded-0" v-if="elemento.imagen!=null" :src = "elemento.imagen"/>
          <img class="card-img rounded-0" v-else src="https://st4.depositphotos.com/17828278/24401/v/600/depositphotos_244011872-stock-illustration-no-image-vector-symbol-missing.jpg"/>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col">
              <div class="card-text">
                <b> {{elemento.nombre}} </b>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="card-text">
                <b> Teléfono: </b> {{elemento.telefono}}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="card-text">
                <b> Dirección: </b> {{elemento.direccion}}
              </div>
            </div>
          </div>
          <div class="row m-2">
            <div class="col">
              <div class="card-text">
                <button class="btn" v-b-toggle="clave">Ver mas</button>
              </div>
            </div>
          </div>
          <div class="row m-2">
            <div class="col">
              <div class="card-text">
                <router-link class="btn" tag="button" :disabled="estado" :to="{name:'forrajesDeVeterinaria',params:{id:elemento.id}}" v-if="boton_extra">
                  Ver forrajes recomendados
                </router-link>
              </div>
            </div>
          </div>        
        </div>
      </div>
      <div class="row">
        <div class="col">
          <Collapse :elemento="elemento" :fecha_guardia="true" :clave="clave"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Collapse from '../components/Collapse.vue'

  export default {
    name: 'Card',
    components: {
      Collapse,
    },
    props: {
        elemento: Object,
        clave: String,
        index: Number,
        boton_extra: Boolean,
    },
    computed: {
      estado: function() {
        return this.elemento.forrajes.length === 0;
      }
    }
  }
</script>

<style scoped>
.col-md-6{
  padding: 0 0 0 0;
}
.card-img {
  width: 100%;
  height: 12rem;
  object-fit: scale-down;
  }
.card {
  background: #ffa6a69e;
}
.btn {
  color: #262626;
  background-color: #FF7070;
  border-color: #FF4747
}
</style>