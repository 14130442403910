import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue/dist/bootstrap-vue.esm';
import VueRouter from 'vue-router';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'bootstrap/dist/css/bootstrap.css';
import vueDebounce from 'vue-debounce'

import router from './routes/router.js'
import './registerServiceWorker'

Vue.use(VueRouter);

Vue.use(BootstrapVue);

Vue.config.productionTip = false

Vue.use(vueDebounce);

new Vue({
  render: h => h(App),
  router: router,
}).$mount('#app')
